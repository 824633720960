import React, { createContext, useRef, useState, useEffect, useMemo } from 'react'
import { IconButton } from '@achieve/ascend'
import { ChevronLeft, ChevronRight } from 'react-feather'
import useViewportSize from 'hooks/useViewportSize'
import useIsSsr from 'hooks/useIsSsr'
import styles from './Carousel.module.scss'
import classNames from 'classnames'
import { iOSSafariMobile } from 'utils/shared'
import { DotIndicators } from 'components/DotIndicators'
import { AchieveLink } from 'components/AchieveLink'

export const CarouselContext = createContext()

function CarouselControl({
  left,
  right,
  disabled,
  classNameControls,
  centerControlsUltra,
  hiddenControls,
  ...props
}) {
  const [classTouchedBtn, setClassTouchedBtn] = useState(false)

  if (!left && !right) {
    return null
  }
  return (
    <div
      data-btn-carousel-touched={classTouchedBtn}
      className={hiddenControls ? styles['hidden'] : styles['carousel-button-container']}
    >
      <AchieveLink
        track={{
          list_name: 'Carousel Nav',
          click_id: left ? 'Left Carousel' : 'Right Carousel',
          click_position: left ? '1' : '2',
          nav_link_section: 'Testimonials',
          track_event: 'ui_click',
          event_action: 'ui_click',
          event_type: 'ui_click',
        }}
      >
        <IconButton
          onTouchStart={() => setClassTouchedBtn(false)}
          onTouchEnd={() => setClassTouchedBtn(true)}
          color="secondary"
          className={classNames(
            classNameControls,
            `${styles['carousel-control']} ${
              left
                ? centerControlsUltra
                  ? styles['control-left-center']
                  : styles['control-left']
                : centerControlsUltra
                ? styles['control-right-center']
                : styles['control-right']
            } ${disabled ? styles['disabled'] : ''}`
          )}
          aria-label={`Display ${left ? 'Previous' : 'Next'} item in carousel`}
          aria-disabled={disabled}
          {...props}
        >
          {left ? <ChevronLeft /> : <ChevronRight />}
        </IconButton>
      </AchieveLink>
    </div>
  )
}

export function Carousel({
  autoPlay = false,
  carouselItems, // TODO - Add docs for required shape of `carouselItems`
  centerControlsUltra = false,
  className,
  classNameActiveDot,
  classNameContainer,
  classNameControls,
  classNameIndicatorDots,
  classNameIndicators,
  classNameItems,
  currentClassName,
  dataItem,
  disableArrows = false,
  hiddenControls = false,
  idPrefix = 'carousel',
  infinityLoop = false,
  interval = 4000,
  itemComponent: ItemComponent,
  variant = 'standard',
  withIndicators = false,
  withBadges = false,
  carouselThreshold = [0.7, 1],
  infinityLoopForever = false,
}) {
  const isSsr = useIsSsr()
  const carouselContainerRef = useRef(null)
  const itemsRef = useRef([])
  itemsRef.current = []
  const [currentItem, setCurrentItem] = useState()
  const [isSafari, setIsSafari] = useState(false)
  const useIntersectionObserverRef = useRef()
  // Use screen height to set intersection observer root margin
  const { screenWidth } = useViewportSize()
  const observerRef = useRef()

  const [onPause, setOnPause] = useState(false)
  const [onHover, setOnHover] = useState(false)
  const [onView, setOnView] = useState(true)
  const [carouselItemsRender, setCarouselItemsRender] = useState(carouselItems || [])
  const [isPrevDisabled, setIsPrevDisabled] = useState(true)
  const [isNextDisabled, setIsNextDisabled] = useState(false)

  const itemIDs = useMemo(
    () => carouselItems?.map((item, index) => `${item?.sys?.id}-${index}`),
    [carouselItems]
  )
  const currentIndex = useMemo(() => itemIDs?.indexOf(currentItem), [currentItem, itemIDs])

  useEffect(() => {
    if (!isSsr) {
      // check If current browser is a safari browser
      // Check if we have available the SafariRemoteNotification function on the window.HTMLElement object when use the window['safari'].pushNotification
      setIsSafari(
        /constructor/i.test(window.HTMLElement) ||
          (function (p) {
            return p.toString() === '[object SafariRemoteNotification]'
          })(
            !window['safari'] ||
              (typeof safari !== 'undefined' && window['safari'].pushNotification)
          ) ||
          iOSSafariMobile(navigator.userAgent)
      )
    }
  }, [isSsr])

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      ([entry]) => {
        const entryID = entry.target.getAttribute('data-id')
        if (entry.isIntersecting && entryID !== currentItem) {
          setCurrentItem(entryID)
        }
      },
      {
        root: carouselContainerRef.current,
        // For some reason, this doesn't seem to check correctly at certain screen widths.
        // Adding a second check here at full view for the case where the check should have fired
        // at 0.7
        threshold: carouselThreshold,
      }
    )
    // Create a new IntersectionObserver for the last item in the carousel
    const lastItemObserver = new IntersectionObserver(
      ([entry]) => {
        let disableNext = false
        // Get the id of the current entry
        const entryID = entry.target.getAttribute('data-id')
        // Check if the current entry is the last item in the carousel
        const isLastItem =
          entryID ===
          `${carouselItems?.[carouselItems.length - 1]?.sys?.id}-${carouselItems.length - 1}`
        // If the current entry is intersecting and it's the last item, disable the next button
        if (entry.isIntersecting) {
          if (isLastItem) {
            disableNext = true
          }
        }
        // Update the state to disable or enable the next button
        setIsNextDisabled(disableNext)
      },
      {
        root: carouselContainerRef.current,
        threshold: carouselThreshold,
        // Apply negative root margin on the right to negate the effect of margin-right on the last item to shift carousel next button over to right
        rootMargin: '0px 0px 0px -90px',
      }
    )

    // Create a new IntersectionObserver for the first item in the carousel
    const firstItemObserver = new IntersectionObserver(
      ([entry]) => {
        let disablePrev = false
        // Get the id of the current entry
        const entryID = entry.target.getAttribute('data-id')
        // Check if the current entry is the first item in the carousel
        const isFirstItem = entryID === `${carouselItems?.[0]?.sys?.id}-0`
        // If the current entry is intersecting and it's the first item, disable the previous button
        if (entry.isIntersecting) {
          if (isFirstItem) {
            disablePrev = true
          }
        }
        setIsPrevDisabled(disablePrev)
      },
      {
        root: carouselContainerRef.current,
        threshold: carouselThreshold,
      }
    )

    itemsRef.current.forEach((e, index) => {
      if (index === 0) {
        firstItemObserver.observe(e)
      }
      if (index === itemsRef.current.length - 1) {
        lastItemObserver.observe(e)
      }
      observerRef.current.observe(e)
    })

    return () => {
      lastItemObserver.disconnect()
      firstItemObserver.disconnect()
      if (!(observerRef.current instanceof IntersectionObserver)) return
      itemsRef.current.forEach((item) => {
        try {
          observerRef.current.unobserve(item)
        } catch (e) {
          // prevent errors on hot reload
          // eslint-disable-line no-empty
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carouselItemsRender])

  useEffect(() => {
    if (
      autoPlay &&
      (!onPause || infinityLoopForever) &&
      (onView || infinityLoopForever) &&
      (!onHover || infinityLoopForever)
    ) {
      const intervalId = setInterval(() => {
        onClickNext()
      }, interval)
      return () => clearInterval(intervalId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoPlay, infinityLoop, interval, onPause, currentIndex, onView, onHover, itemIDs])

  useEffect(() => {
    if (isSsr) {
      // no window to measure during SSR
      return
    }
    window.addEventListener('scroll', scrollHandler)
    return () => window.removeEventListener('scroll', scrollHandler)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSsr])

  const scrollHandler = () => {
    const lineTop = window.pageYOffset
    const borderTop = useIntersectionObserverRef?.current?.offsetTop
    const borderBotton = borderTop + useIntersectionObserverRef?.current?.offsetHeight
    setOnView(!(lineTop >= borderBotton))
  }

  useEffect(() => {
    setCarouselItemsRender(carouselItems)
  }, [carouselItems, infinityLoop, currentIndex, isSafari])

  const setItemsRef = (el, id, pos) => {
    if (el) {
      el.setAttribute('data-id', `${id}-${pos}`)
      itemsRef.current[pos] = el
    }
  }

  const showItem = (id) => {
    const actualPos = itemIDs.indexOf(currentItem)
    const newPos = itemIDs.indexOf(id)
    const widthScroll =
      variant === 'standard'
        ? carouselContainerRef.current.getBoundingClientRect().width
        : screenWidth
    if (actualPos === newPos) return
    const difference = widthScroll * Math.abs(newPos - actualPos)
    carouselContainerRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'nearest',
    })
    carouselContainerRef.current.scrollBy({
      left: actualPos > newPos ? -difference : difference,
      behavior: 'smooth',
    })
    if (isSafari) {
      setCurrentItem(id)
    }
  }

  const CarouselContainer = useMemo(() => {
    return carouselItemsRender.map((item, pos) => (
      <div
        id={item?.sys?.id}
        className={classNames(
          styles['carousel-container-item'],
          styles[variant],
          currentItem === `${item?.sys?.id}-${pos}` && currentClassName,
          classNameItems
        )}
        key={`${item?.sys?.id}-${pos}`}
        data-testid={`${idPrefix}-slide-${
          currentItem === item?.sys?.id ? 'selected' : 'unselected'
        }`}
        aria-roledescription="slide"
        ref={(el) => setItemsRef(el, item?.sys?.id, pos)}
      >
        <ItemComponent
          item={item}
          idPrefix={idPrefix}
          data-testid={`${idPrefix}-item`}
          selected={currentItem === `${item?.sys?.id}-${pos}`}
          index={pos}
          {...dataItem}
        />
      </div>
    ))
  }, [
    carouselItemsRender,
    classNameItems,
    currentClassName,
    currentItem,
    dataItem,
    idPrefix,
    variant,
  ])

  if (carouselItems?.length < 1) return null

  const onClickPrev = () => {
    if (currentIndex === 0 && infinityLoop) {
      showItem(itemIDs[itemIDs.length - 1])
    } else if (currentIndex > 0) {
      showItem(itemIDs[currentIndex - 1])
    }
  }

  const onClickNext = () => {
    if (currentIndex === itemIDs.length - 1 && infinityLoop) {
      showItem(itemIDs[0])
    } else if (currentIndex < itemIDs.length - 1) {
      showItem(itemIDs[currentIndex + 1])
    }
  }

  return (
    <CarouselContext.Provider
      value={{
        currentItem,
        showItem,
        carouselItems,
        setOnPause,
      }}
    >
      <div
        id="carousel-achieve"
        ref={useIntersectionObserverRef}
        data-arrow={!disableArrows}
        className={classNames(
          className ? className : '',
          styles['carousel-wrapper'],
          styles[variant]
        )}
        onMouseEnter={() => setOnHover(true)}
        onMouseLeave={() => setOnHover(false)}
        onTouchStart={() => setOnHover(true)}
      >
        {!disableArrows && (
          <AchieveLink
            noLink
            track={{
              list_name: 'Carousel Nav',
              click_id: 'Left Carousel',
              click_position: '1',
              nav_link_section: 'Testimonials',
              track_event: 'ui_click',
              event_action: 'ui_click',
              event_type: 'ui_click',
            }}
          >
            <CarouselControl
              left
              onClick={() => {
                onClickPrev()
                setOnPause(true)
              }}
              data-testid={`${idPrefix}-control-prev`}
              disabled={!infinityLoop && isPrevDisabled}
              aria-disabled={!infinityLoop && isPrevDisabled}
              classNameControls={classNameControls}
              centerControlsUltra={centerControlsUltra}
              hiddenControls={hiddenControls}
            />
          </AchieveLink>
        )}
        <div
          className={classNames(styles['carousel-container'], styles[variant], classNameContainer)}
          data-testid={`${idPrefix}-container`}
          data-with-badges={withBadges}
          ref={carouselContainerRef}
          aria-roledescription="carousel"
          role="group"
          onTouchEnd={() => setOnPause(false)}
        >
          {CarouselContainer}
        </div>
        {!disableArrows && (
          <AchieveLink
            noLink
            track={{
              list_name: 'Carousel Nav',
              click_id: 'Right Carousel',
              click_position: '2',
              nav_link_section: 'Testimonials',
              track_event: 'ui_click',
              event_action: 'ui_click',
              event_type: 'ui_click',
            }}
          >
            <CarouselControl
              right
              onClick={() => {
                onClickNext()
                setOnPause(true)
              }}
              data-testid={`${idPrefix}-control-next`}
              disabled={!infinityLoop && isNextDisabled}
              aria-disabled={!infinityLoop && isNextDisabled}
              classNameControls={classNameControls}
              centerControlsUltra={centerControlsUltra}
              hiddenControls={hiddenControls}
            />
          </AchieveLink>
        )}
      </div>
      {withIndicators && (
        <DotIndicators
          classNameIndicatorDots={classNameIndicatorDots}
          classNameActiveDot={classNameActiveDot}
          classNameIndicators={classNameIndicators}
          size={itemIDs.length}
          activeIndex={currentIndex}
        />
      )}
    </CarouselContext.Provider>
  )
}
