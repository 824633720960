import styles from './HeroCarousel.module.scss'
import { Carousel } from 'components/Carousels'
import { MediaImageStandard, Typography, LinkButton } from 'components/Contentful'
import { Box } from '@achieve/ascend'
import { BREAKPOINTS } from 'constants/viewport'
import useViewportSize from 'hooks/useViewportSize'
import { get as _get } from 'lodash-es'
import classNames from 'classnames'
import { useBot } from 'hooks/useBot'

const FONT_MAP = {
  BUGLINE: {
    [BREAKPOINTS.xxs]: { variant: 'bodyLg', weight: 'regular' },
    [BREAKPOINTS.xs]: { variant: 'bodyLg', weight: 'regular' },
    [BREAKPOINTS.sm]: { variant: 'bodyLg', weight: 'regular' },
    [BREAKPOINTS.md]: { variant: 'headingMd', weight: 'medium' },
    [BREAKPOINTS.lg]: { variant: 'headingMd', weight: 'medium' },
    [BREAKPOINTS.xl]: { variant: 'headingMd', weight: 'medium' },
    [BREAKPOINTS.xxl]: { variant: 'headingMd', weight: 'medium' },
  },
  TITLE: {
    [BREAKPOINTS.xxs]: { variant: 'headingLg', weight: 'bold' },
    [BREAKPOINTS.xs]: { variant: 'headingLg', weight: 'bold' },
    [BREAKPOINTS.sm]: { variant: 'headingLg', weight: 'bold' },
    [BREAKPOINTS.md]: { variant: 'displayXl', weight: 'bold' },
    [BREAKPOINTS.lg]: { variant: 'displayXl', weight: 'bold' },
    [BREAKPOINTS.xl]: { variant: 'displayXl', weight: 'bold' },
    [BREAKPOINTS.xxl]: { variant: 'displayXl', weight: 'bold' },
  },
  BUTTON: {
    [BREAKPOINTS.xxs]: { variant: 'bodySm', weight: 'bold' },
    [BREAKPOINTS.xs]: { variant: 'bodySm', weight: 'bold' },
    [BREAKPOINTS.sm]: { variant: 'bodySm', weight: 'bold' },
    [BREAKPOINTS.md]: { variant: 'bodyLg', weight: 'bold' },
    [BREAKPOINTS.lg]: { variant: 'bodyLg', weight: 'bold' },
    [BREAKPOINTS.xl]: { variant: 'bodyLg', weight: 'bold' },
    [BREAKPOINTS.xxl]: { variant: 'bodyLg', weight: 'bold' },
  },
}
function Card({
  item: {
    title,
    bugline,
    backgroundMobile,
    background,
    alt,
    buttonCta,
    priority,
    titleColor,
    buglineColor,
    titlePosition,
    buglinePosition,
    titleShadow,
    buglineShadow,
  },
  selected,
}) {
  const isBot = useBot()
  const DEFAULT_COLOR = 'font-white'
  const DEFAULT_SHADOW = 'shadow-none'
  const finalTitleColor = titleColor ? `font-${titleColor.toLowerCase()}` : DEFAULT_COLOR
  const finalBuglineColor = buglineColor ? `font-${buglineColor.toLowerCase()}` : DEFAULT_COLOR
  const finalTitleShadow = titleShadow ? `shadow-${titleShadow.toLowerCase()}` : DEFAULT_SHADOW
  const finalBuglineShadow = buglineShadow
    ? `shadow-${buglineShadow.toLowerCase()}`
    : DEFAULT_SHADOW

  const { breakpointLetterSize } = useViewportSize()
  return (
    <Box
      className={[styles['card-hero'], selected && styles['card-hero-selected']]}
      elevation={selected ? 10 : 1}
    >
      <div className={styles['card-hero-block']}>
        <span className={styles['card-hero-image']}>
          <MediaImageStandard
            content={
              [BREAKPOINTS.xxs, BREAKPOINTS.xs, BREAKPOINTS.sm].includes(breakpointLetterSize)
                ? backgroundMobile
                : background
            }
            alt={alt}
            layout="fill"
            objectFit="cover"
            objectPosition="top"
            priority={priority}
          />
        </span>
        <Box className={styles['card-hero-content']} component="div">
          <Typography
            content={bugline}
            variant={FONT_MAP.BUGLINE[breakpointLetterSize].variant}
            fontWeight={FONT_MAP.BUGLINE[breakpointLetterSize].weight}
            className={classNames(
              styles['card-hero-content-bugline'],
              styles[finalBuglineColor],
              styles[finalBuglineShadow],
              ...[buglinePosition && styles[`bugline-${buglinePosition.toLowerCase()}`]]
            )}
            component={selected ? 'h1' : 'p'}
          />
          <Typography
            content={title}
            variant={FONT_MAP.TITLE[breakpointLetterSize].variant}
            fontWeight={FONT_MAP.TITLE[breakpointLetterSize].weight}
            className={classNames(
              styles['card-hero-content-info'],
              styles[finalTitleShadow],
              styles[finalTitleColor],
              ...[titlePosition && styles[`title-${titlePosition.toLowerCase()}`]]
            )}
          />
          <span
            className={classNames(
              ...[titlePosition && styles[`title-${titlePosition.toLowerCase()}`]]
            )}
          >
            <LinkButton
              track={{
                ...{
                  list_name: 'Hero Carousel Event',
                  hero_carousel_section: 'Hero CTA Clicked',
                  click_type: 'Button Click',
                  click_id: _get(buttonCta, 'linkText.content.0.content.0.value', ''),
                  click_url: _get(buttonCta, 'linkHref', ''),
                  click_text: _get(buttonCta, 'linkText.content.0.content.0.value', ''),
                  click_position: '0',
                  nav_link_section: 'Hero Carousel',
                  track_event: 'internal_campaign_click',
                  event_action: 'ui_click',
                  event_type: 'ui_click',
                  isBot,
                },
                ...buttonCta?.fields?.event,
              }}
              content={buttonCta}
              typographicOptions={{
                variant: FONT_MAP.BUTTON[breakpointLetterSize].variant,
                fontWeight: FONT_MAP.BUTTON[breakpointLetterSize].weight,
              }}
              size="large"
              classes={{
                root: styles['card-hero-content-link'],
              }}
            />
          </span>
        </Box>
      </div>
    </Box>
  )
}

function HeroCarousel({ slides }) {
  return (
    <Box className={styles['carousel-container']}>
      <Carousel
        className={styles['hero-carousel-wrapper']}
        carouselItems={slides}
        itemComponent={Card}
        variant="standard"
        idPrefix={`home-hero`}
        withIndicators
        classNameIndicators={styles['hero-carousel-wrapper-indicators']}
        classNameControls={styles['hero-carousel-wrapper-controls']}
        autoPlay
        infinityLoop
      />
    </Box>
  )
}

export { HeroCarousel }
