import { Button as AscendButton } from '@achieve/ascend'
import { handleTrackAndReactEvent } from 'utils/analytics'
import { useTriggerEvents } from 'hooks/useTriggerEvents'

function Button(props) {
  let track = props.track
  const { fireAndForget } = useTriggerEvents()

  const localClickHandler = (event, track, onClick) => {
    fireAndForget({
      event_type: track?.event_type,
      ...handleTrackAndReactEvent(event, track, onClick),
    })
  }

  return props.defaultbutton ? (
    <button
      {...props}
      onClick={(event) => {
        localClickHandler(event, track, props.onClick)
      }}
    >
      {props.children}
    </button>
  ) : (
    <AscendButton
      {...props}
      onClick={(event) => {
        localClickHandler(event, track, props.onClick)
      }}
    >
      {props.children}
    </AscendButton>
  )
}

export { Button }
